import React, { useContext, useEffect, useCallback } from 'react';
import * as S from './ToggleContainer.styles';
import FunnelContext from '../../../context/FunnelContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

const ToggleContainerMultiple = props => {
  const {
    builderBlock,
    children,
    activeColor,
    hoverColor,
    containers,
    showTickbox,
    tickboxColor,
    tickboxColorActive,
    direction,
    tickboxPositionTop,
    tickboxPositionLeft,
    tickboxRadius,
    checkAnswer,
    checkAnswerKey,
    checkAnswerValue
  } = props;

  const {
    extraObjects: extraData,
    setExtraObjects,
    answers: answersContext,
    setAnswers,
    setCurrentTubIndex,
    setCurrentCategory,
    setCurrentProduct,
    funnelUpdated,
    setFunnelUpdated
  } = useContext(FunnelContext);

  const [active, setActive] = useLocalStorage(
    `toggleContainerMultipleActive-${builderBlock.id}`,
    ''
  );
  const [extraObjects2, setExtraObjects2] = useLocalStorage('extraObjects', {});
  const [answers2, setAnswers2] = useLocalStorage('answers', {});
  const [currentTubIndex2, setCurrentTubIndex2] = useLocalStorage(
    'currentTubIndex',
    null
  );
  const [currentCategory2, setCurrentCategory2] = useLocalStorage(
    'currentCategory',
    null
  );

  const handleClick = useCallback(
    (index, container) => {
      const {
        extraObjects,
        setAnswer,
        currentTubIndex,
        currentCategory,
        setFilterByTag,
        filterByTagName
      } = container;
      const newExtraData = extraData;
      const newAnswerData = answersContext;
      Object.assign(newExtraData, { [builderBlock.id]: index });

      extraObjects.map(({ key, value }) => {
        Object.assign(newExtraData, { [key]: value });
        Object.assign(newAnswerData, { [key]: value });
        return null;
      });

      if (setFilterByTag) {
        Object.assign(newExtraData, {
          filterProductItemsByTag: filterByTagName
        });
      }

      if (setAnswer) {
        setAnswers(newAnswerData);
        setAnswers2(newAnswerData);
      }

      setExtraObjects(newExtraData);
      setExtraObjects2(newExtraData);
      setActive(index);

      if (currentTubIndex || currentTubIndex === 0) {
        setCurrentTubIndex(currentTubIndex);
        setCurrentTubIndex2(currentTubIndex);
      }

      if (currentCategory) {
        setCurrentCategory(currentCategory);
        setCurrentCategory2(currentCategory);

        const productPrefix =
          currentCategory === `onetime` ? `onetime` : `subscription`;
        setCurrentProduct(`${productPrefix}_${currentTubIndex}`);
      }
      setFunnelUpdated(!funnelUpdated);
    },
    [
      extraData,
      setActive,
      builderBlock,
      setExtraObjects,
      setExtraObjects2,
      answersContext,
      setAnswers,
      setAnswers2,
      setCurrentTubIndex,
      setCurrentCategory,
      setCurrentCategory2,
      setCurrentTubIndex2,
      setCurrentProduct,
      funnelUpdated,
      setFunnelUpdated
    ]
  );

  useEffect(() => {
    if (checkAnswer && answersContext[checkAnswerKey] !== checkAnswerValue) {
      return;
    }

    const newExtraData = Object.assign(extraData, extraObjects2);
    setExtraObjects(newExtraData);
    setExtraObjects2(newExtraData);

    const newAnswerData = Object.assign(answersContext, answers2);
    setAnswers(newAnswerData);
    setAnswers2(newAnswerData);

    if (currentTubIndex2 || currentTubIndex2 === 0) {
      setCurrentTubIndex(currentTubIndex2);
    }
    if (currentCategory2) {
      setCurrentCategory(currentCategory2);
    }

    if (!extraData[`loadSelected-${builderBlock.id}`]) {
      containers.every((container, index) => {
        if (container.selected) {
          handleClick(index, container);
          return false;
        }
        return true;
      });
      setExtraObjects(
        Object.assign(extraData, {
          [`loadSelected-${builderBlock.id}`]: true
        })
      );
    }
  }, [
    containers,
    extraData,
    setActive,
    builderBlock,
    setExtraObjects,
    extraObjects2,
    setExtraObjects2,
    answersContext,
    setAnswers,
    answers2,
    setAnswers2,
    currentTubIndex2,
    currentCategory2,
    setCurrentTubIndex,
    setCurrentCategory,
    handleClick,
    checkAnswer,
    checkAnswerKey,
    checkAnswerValue
  ]);

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  const Container = () => {
    return containers.map((container, index) => {
      return (
        <S.Wrapper
          count={containers.length}
          direction={direction ?? `column`}
          key={index}
          className={`toggle-container-wrapper ${
            active === index ? `active` : ``
          }`}
        >
          <S.Container
            onClick={() => handleClick(index, container)}
            active={active === index}
            hoverColor={hoverColor}
            activeColor={activeColor}
            className={`toggle-container-container ${
              active === index ? `active` : ``
            }`}
          >
            {showTickbox && (
              <S.Dot
                className="toggle-container-dot"
                tickboxRadius={tickboxRadius}
                active={active === index}
                tickboxColor={tickboxColor}
                tickboxColorActive={tickboxColorActive}
                tickboxPositionTop={tickboxPositionTop}
                tickboxPositionLeft={tickboxPositionLeft}
              />
            )}
            <div
              className={`toggle-container-children ${
                active === index ? `active` : ``
              }`}
            >
              {children[index] || noChildren()}
            </div>
          </S.Container>
        </S.Wrapper>
      );
    });
  };

  return <Container />;
};

export default ToggleContainerMultiple;
