import styled from 'styled-components';
import { queries } from '../../../utils/mediaQueries';

export const Wrapper = styled.div`
  ${({ count, direction }) => {
    if (count) {
      let directionCss =
        direction === 'row'
          ? ``
          : `
            max-width: calc(100% / ${count});
            padding: 0 10px;
        `;
      return `
            ${directionCss}
            @media ${queries.md} {
                max-width: 100%!important;
                padding: 0;
              }
        `;
    }
  }}
`;

export const Container = styled.div`
  position: relative;
  ${({ active, bgColor, activeColor, hoverColor }) => {
    if (active) {
      return `
        & > div > div {
            background-color: ${activeColor}!important;
        }
        `;
    }

    return `
    & > div > div {
        background-color: ${bgColor};
    }
    &:hover {
        & > div > div {
            background-color: ${hoverColor};
        }
    }`;
  }}
`;

export const NoChildren = styled.div`
  padding: 50px;
  text-align: center;
  font-style: italic;
  font-size: 12px;
`;

export const Dot = styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 999;
    border-radius: 100%;
    background: white;
    cursor: pointer;
    
    ${({ useCheckmark }) =>
      useCheckmark
        ? `
    &:after { 
      display: block;
      content: " ";
      width: 8px;
      height: 18px;
      border-radius: 0;
      border: 3px solid transparent;
      transform: rotate(37deg);
      transform-origin: 100% 100%;
      margin: -1px;
    }
    `
        : `
      &:after { 
        display: block;
        content: " ";
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin: 4px;
      }
    `}

    ${({ tickboxColor }) => `border: 1px solid ${tickboxColor};`}}
    ${({ tickboxRadius, useCheckmark }) =>
      !useCheckmark
        ? `
        border-radius: ${tickboxRadius}!important;
        &:after { 
            border-radius: ${tickboxRadius}!important;
        }
    `
        : `
      border-radius: ${tickboxRadius}!important;
    `}

    ${({ tickboxPositionTop, tickboxPositionLeft }) => {
      return `
            margin-top: ${tickboxPositionTop ?? 10}px;
            margin-left: ${tickboxPositionLeft ?? 20}px;
        `;
    }}

    ${({ active, tickboxColor, tickboxColorActive }) =>
      active
        ? `border: 1px solid ${tickboxColorActive};`
        : `border: 1px solid ${tickboxColor};`}}

    ${({ active, tickboxColor, tickboxColorActive, useCheckmark }) => {
      if (active) {
        return useCheckmark
          ? `
        &:after { 
          border-right: 3px solid ${tickboxColorActive || tickboxColor};
          border-bottom: 3px solid ${tickboxColorActive || tickboxColor};
        }
          `
          : `
            &:after { 
                background: ${tickboxColorActive || tickboxColor}!important;
            }`;
      } else {
        return ``;
      }
    }}
`;
